import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import ViewCard, { ExternalViewCard, ViewCardProps } from 'common/components/ViewCard';

interface ExampleProps {
  viewCardProps: ViewCardProps;
}

const ExternalExample: FunctionComponent<ExampleProps> = ({ viewCardProps, children }) => {
  /* eslint-disable @typescript-eslint/indent */
  //extra spaces here look bad in the demo
  const code = `const props = ${JSON.stringify(viewCardProps, null, 2)};
return (
  <ExternalViewCard {...props} />
);`;
  /* eslint-enable @typescript-eslint/indent */

  return (
    <div className="styleguide-example">
      {children}
      <ExternalViewCard {...viewCardProps} />
      <pre className="styleguide-code-example">
        {code}
      </pre>
    </div>
  );
};

const Example: FunctionComponent<ExampleProps> = ({ viewCardProps, children }) => {
  /* eslint-disable @typescript-eslint/indent */
  //extra spaces here look bad in the demo
  const code = `const props = ${JSON.stringify(viewCardProps, null, 2)};
return (
  <ViewCard {...props} />
);`;
    /* eslint-enable @typescript-eslint/indent */

  return (
    <div className="styleguide-example">
      {children}
      <ViewCard {...viewCardProps} />
      <pre className="styleguide-code-example">
        {code}
      </pre>
    </div>
  );
};

const ViewCardDemo: FunctionComponent = () => {
  const example1Props = {
    name: 'Chicago Crimes',
    description: 'This dataset reflects <b>reported incidents</b> of crime that ' +
      'occurred in the <i>City of Chicago</i> from 2001 to present, minus the most recent ' +
      'seven days. Also this description is really long and might even ellipsify.',
    url: 'https://data.cityofchicago.org/view/5cd6-ry5g',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/Patch_of_the_Chicago_Police_Department.png',
    icon: 'socrata-icon-cards',
    metadataLeft: 'Updated Jan 1, 1970',
    metadataRight: '1,234 views',
    isPrivate: true,
    linkProps: { target: '_blank' },
    onClick: (event: React.MouseEvent) => {
      console.log(event);
    }
  };

  const example2Props = {
    name: 'Chicago Crimes',
    description: 'This dataset reflects <b>reported incidents</b> of crime that ' +
      'occurred in the <i>City of Chicago</i> from 2001 to present, minus the most recent ' +
      'seven days. Also this description is really long and might even ellipsify.',
    url: 'https://data.cityofchicago.org/view/5cd6-ry5g',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Chicago_Police_Interceptor_Utility.JPG/300px-Chicago_Police_Interceptor_Utility.JPG',
    icon: 'socrata-icon-cards',
    metadataLeft: 'Updated Jan 1, 1970',
    metadataRight: '1,234 views',
    isPrivate: true,
    linkProps: {target: '_blank' },
    onClick: (event: React.MouseEvent) => {
      console.log(event);
    }
  };

  const example3Props = {
    name: 'Carrots',
    description: "I bet you don't carrot all about what I write here for the description.",
    url: 'https://en.wikipedia.org/wiki/Carrot',
    imageUrl: 'http://www.almanac.com/sites/default/files/styles/primary_image_in_article/public/images/carrots.jpg'
  } as ViewCardProps;

  const example4Props = {
    name: 'Chicago Crimes',
    description: 'This dataset reflects <b>reported incidents</b> of crime that ' +
      'occurred in the <i>City of Chicago</i> from 2001 to present, minus the most recent ' +
      'seven days. Also this description is really long and might even ellipsify.',
    url: 'https://data.cityofchicago.org/view/5cd6-ry5g',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Chicago_Police_Interceptor_Utility.JPG/300px-Chicago_Police_Interceptor_Utility.JPG',
    icon: 'socrata-icon-cards',
    metadataLeft: 'Updated Jan 1, 1970',
    metadataRight: '1,234 views',
    isPrivate: true,
    linkProps: {target: '_blank' },
    onClick: (event: React.MouseEvent) => {
      console.log(event);
    },
    scope: 'public'
  } as ViewCardProps;

  const example5Props = {
    name: 'Chicago Crimes',
    description: 'This dataset reflects <b>reported incidents</b> of crime that ' +
      'occurred in the <i>City of Chicago</i> from 2001 to present, minus the most recent ' +
      'seven days. Also this description is really long and might even ellipsify.',
    url: 'https://data.cityofchicago.org/view/5cd6-ry5g',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Chicago_Police_Interceptor_Utility.JPG/300px-Chicago_Police_Interceptor_Utility.JPG',
    icon: 'socrata-icon-cards',
    metadataLeft: 'Updated Jan 1, 1970',
    metadataRight: '1,234 views',
    isPrivate: false,
    isFederated: true,
    sourceDomain: 'source.domain.com',
    linkProps: {target: '_blank' },
    onClick: (event: React.MouseEvent) => {
      console.log(event);
    },
    scope: 'public'
  } as ViewCardProps;

  return (<div>
    <Example viewCardProps={example1Props}>
      <p>View Cards are general-purpose tiles representing an asset.  They might be shown in a catalog, in
      an asset selector, or anywhere else where a list of views needs to be rendered.  The component
      features a title, a section for arbitrary metadata, a preview image of the asset, and a description.
      The title and the description are automatically ellipsified.  The title and image are clickable
        links.</p>

      <div className="alert info">
        Not sure which icon to use?  For a mapping of view display types to icon classes, see the helper
          in <code>
          <a href="https://github.com/socrata/platform-ui/blob/main/common/displayTypeMetadata.js">
            common/displayTypeMetadata.js
          </a>
        </code>.
      </div>
    </Example>

    <Example viewCardProps={example2Props}>
      <p>
        Sometimes it is helpful to render a spinner or a button on top of the card to display more
        information or allow an action to be taken.  Simply pass the desired element as a child of the
        ViewCard and it will be rendered as an overlay:
      </p>
    </Example>

    <ExternalExample viewCardProps={example3Props}>
      <p>
        For external assets, a component ExternalViewCard is provided, which applies attributes to the
        anchor tags informing search engines that the link is an external link, makes the link open in a new
        tab, sets the icon to one appropriate for external links, and sets the metadataLeft prop to
        "External Content".
      </p>
    </ExternalExample>

    <Example viewCardProps={example4Props}>
      <p>
        If you want to display the asset's audience (Private, Internal, or Public), provide a `scope`
        attribute to the components and see the relevant labeled icon appear.
      </p>
    </Example>

    <Example viewCardProps={example5Props}>
      <p>
        If you want to display the asset's federation status, include both <code>isFederated</code> and
        <code>sourceDomain</code> props and the federation icon and banner will appear as shown below.
      </p>
    </Example>
  </div>);
};

window.onload = () => {
  ReactDOM.render(
    React.createElement(ViewCardDemo),
    document.getElementById('view-card-demo')
  );
};
